<template>
  <section class="pt-4 px-4">
    <b-row class="mb-2">
      <b-col>
        <h1>
          Stai modificando <b-badge>{{ item.brand }} {{ item.code }}</b-badge>
        </h1>
      </b-col>
      <b-col class="text-right">
        Last update: 12/07/2023
      </b-col>
    </b-row>
    <b-card title="Immagini" class="mb-3">
      <b-container fluid>
        <b-row class="mb-2" v-if="item.images && item.images.thumb">
          <b-card
            v-for="(image, index) in item.images.thumb"
            :key="index"
            class="m-1"
            style="width: 16%"
            overlay
            :img-src="image"
          />
        </b-row>
      </b-container>
      <b-card>
        <b-card-title class="h6">Upload</b-card-title>
        <upload name="images" @success="updateImages" />
      </b-card>
    </b-card>
    <b-card title="Categorie e Produttore" class="mb-3">
      <b-container fluid>
        <b-row class="mb-4">
          <b-col>
            <b-select v-model="item.brandId" :options="brands" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <product-category
              :category-path="categoryPath"
              @categoryId="setCategoryId"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card title="Descrizione" class="mb-3">
      <b-container fluid>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="barcode">Barcode:</label>
          </b-col>
          <b-col cols="4">
            <b-form-input
              id="barcode"
              placeholder="1234567890123"
              v-model="item.barcode"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-default">Codice:</label>
          </b-col>
          <b-col cols="4">
            <b-form-input
              id="code"
              placeholder="123456"
              v-model="item.code"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-default">Slug:</label>
          </b-col>
          <b-col cols="10">
            <b-input-group>
              <template #append>
                <b-input-group-text>
                  <font-awesome-icon icon="lock"
                /></b-input-group-text>
              </template>
              <b-form-input
                id="title"
                placeholder="titolo-seo-frienly"
                :value="toSeoUrl(item.name)"
                disabled
                class="font-italic"
              ></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-default">Titolo:</label>
          </b-col>
          <b-col cols="10">
            <b-form-input
              id="title"
              placeholder="Breve descrizione dell'articolo"
              v-model="item.name"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-default">Descrizione:</label>
          </b-col>
          <b-col cols="10">
            <b-form-textarea
              id="textarea"
              v-model="item.description"
              placeholder="Descrizione dell'articolo"
              rows="4"
              max-rows="6"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col cols="2">
            <label for="input-default">Dettagli:</label>
          </b-col>
          <b-col cols="10">
            <b-form-textarea
              id="textarea"
              v-model="item.detail"
              placeholder="Dettagli dell'articolo"
              rows="6"
              max-rows="6"
            ></b-form-textarea>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card title="File e Media pubblici" class="mb-3">
      <b-container fluid>
        <b-row
          v-for="(video, index) in embeddedVideo"
          :key="index"
          class="mb-3"
        >
          <b-col cols="5">
            <b-form-input
              id="embedded-video"
              placeholder="ID video"
              v-model="video.id"
            ></b-form-input>
          </b-col>
          <b-col cols="5">
            <b-input-group>
              <template #prepend v-if="video.platform === 'youtube'">
                <b-input-group-text>
                  <font-awesome-icon
                    :icon="['fab', 'youtube']"
                    class="text-danger"
                  />
                </b-input-group-text>
              </template>
              <b-select
                v-model="video.platform"
                :options="embeddedVideoPlatform"
              />
            </b-input-group>
          </b-col>
          <b-col cols="2">
            <b-button @click="removeEmbeddedVideo(index)" block variant="danger"
              ><font-awesome-icon icon="trash"
            /></b-button>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-button @click="insertEmbeddedVideo()" variant="primary">
              <font-awesome-icon icon="plus"
            /></b-button>
          </b-col>
        </b-row>
      </b-container>
      <b-card>
        <b-card-title class="h6">Upload</b-card-title>
        <upload name="attachment" background-color="00800099" />
      </b-card>
    </b-card>
    <b-card title="Allegati privati" class="mb-3">
      <b-card class="mb-3">
        <b-card-title class="h6">Upload</b-card-title>
        <upload name="private-attachment" background-color="ff000099" />
      </b-card>
      <b-form-group label="Istruzioni private">
        <b-form-textarea
          placeholder="Scrivi qua le istruzioni per gli allegati privati"
          rows="6"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>
    </b-card>
    <b-card title="Prezzi" class="mb-3">
      <b-container fluid>
        <!--<b-row class="mb-2">
          <b-col> calcola sconti {{ item.code }} </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-form-input type="text" />
          </b-col>
          <b-col>
            <b-form-input type="text" />
          </b-col>
          <b-col>
            <b-button>+</b-button>
          </b-col>
        </b-row>-->
        <b-row class="mb-2">
          <b-col class="text-center">
            <pricing-bar :percents="percents" :max-amount="sellPrice" />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <label for="cost-price"
              >Costo
              <font-awesome-icon
                v-if="parseFloat(item.costPrice) !== store.costPrice"
                :icon="
                  item.costPrice > store.costPrice
                    ? 'long-arrow-alt-up'
                    : 'long-arrow-alt-down'
                "
                :class="
                  item.costPrice >= store.costPrice
                    ? 'text-success'
                    : 'text-danger'
                "
            /></label>
            <b-form-group label-for="cost-price">
              <b-form-input
                v-model="item.costPrice"
                id="cost-price"
                type="text"
                required
                @keyup="calculate"
                @focus="inputCostPrice = true"
                @blur="inputCostPrice = false"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Iva" label-for="vat">
              <b-form-select
                id="vat"
                v-model="item.vatId"
                :options="[
                  { value: 1, text: '22%' },
                  { value: 2, text: '0% Editoria' },
                  { value: 3, text: '0% Buoni regalo' }
                ]"
                @change="calculate"
                @focus="selectVatValue = true"
                @blur="selectVatValue = false"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <label for="markup"
              >Ricarico (%)
              <font-awesome-icon
                v-if="markup !== store.markup"
                :icon="
                  markup > store.markup
                    ? 'long-arrow-alt-up'
                    : 'long-arrow-alt-down'
                "
                :class="markup >= store.markup ? 'text-success' : 'text-danger'"
            /></label>
            <b-form-group label-for="markup">
              <b-form-input
                v-model="markup"
                id="markup"
                type="text"
                :state="markup > 0"
                required
                @keyup="calculate"
                @focus="inputMarkup = true"
                @blur="inputMarkup = false"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <label for="sell-price"
              >Vendita
              <font-awesome-icon
                v-if="parseFloat(item.sellPrice) !== store.sellPrice"
                :icon="
                  item.sellPrice > store.sellPrice
                    ? 'long-arrow-alt-up'
                    : 'long-arrow-alt-down'
                "
                :class="
                  item.sellPrice >= store.sellPrice
                    ? 'text-success'
                    : 'text-danger'
                "
            /></label>
            <b-form-group label-for="sell-price">
              <b-form-input
                v-model="item.sellPrice"
                id="sell-price"
                type="text"
                required
                @keyup="calculate"
                @focus="inputSellPrice = true"
                @blur="inputSellPrice = false"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <label for="markup"
              >Sconto (%)
              <font-awesome-icon
                v-if="discount !== store.discount"
                :icon="
                  discount > store.discount
                    ? 'long-arrow-alt-up'
                    : 'long-arrow-alt-down'
                "
                :class="
                  discount >= store.discount ? 'text-success' : 'text-danger'
                "
            /></label>
            <b-form-group label-for="discount">
              <b-form-input
                v-model="discount"
                id="discount"
                type="text"
                :state="discount >= 0"
                required
                @keyup="calculate"
                @focus="inputDiscount = true"
                @blur="inputDiscount = false"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <label for="list-price"
              >Listino
              <font-awesome-icon
                v-if="parseFloat(item.listPrice) !== store.listPrice"
                :icon="
                  item.listPrice > store.listPrice
                    ? 'long-arrow-alt-up'
                    : 'long-arrow-alt-down'
                "
                :class="
                  item.listPrice >= store.listPrice
                    ? 'text-success'
                    : 'text-danger'
                "
            /></label>
            <b-form-group label-for="list-price">
              <b-form-input
                v-model="item.listPrice"
                id="list-price"
                type="text"
                @keyup="calculate"
                @focus="inputListPrice = true"
                @blur="inputListPrice = false"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="text-center font-italic mb-2">
          <b-col>
            {{ currencyFormatter(store.costPrice) }}
          </b-col>
          <b-col> {{ getVatValue(store.vatId) }}% </b-col>
          <b-col> {{ store.markup }}% </b-col>
          <b-col class="font-weight-bold">
            {{ currencyFormatter(store.sellPrice) }}
          </b-col>
          <b-col> {{ store.discount }}% </b-col>
          <b-col>
            {{ currencyFormatter(store.listPrice) }}
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <b-button @click="reset" variant="outline-primary" block
              >Reset</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-card title="Disponibilità" class="mb-3">
      <b-container fluid>
        <b-row class="mb-2"> </b-row>
      </b-container>
    </b-card>
    <b-card title="Visibilità" class="mb-3">
      <b-container fluid>
        <b-row class="mb-2"> </b-row>
      </b-container>
    </b-card>
  </section>
</template>

<script>
import { urlMixin } from "@/mixins/urlMixin";
import PricingBar from "@/components/PricingBar.vue";
import ProductCategory from "@/components/ProductCategory.vue";
import Upload from "@/components/Upload.vue";

const VatValueList = {
  1: 22,
  2: 0,
  3: 0
};

export default {
  name: "Product",
  mixins: [urlMixin],
  components: {
    Upload,
    ProductCategory,
    PricingBar
  },
  data() {
    return {
      itemId: this.$route.params.itemId,
      item: {},
      markup: null,
      discount: null,
      inputCostPrice: false,
      selectVatValue: false,
      inputMarkup: false,
      inputSellPrice: false,
      inputDiscount: false,
      inputListPrice: false,
      store: {},
      brands: [],
      categoryPath: [],
      //categoryId: null,
      immagini: [
        "https://www.tecnomodel-treni.it/images/300x300/75789-0.jpg",
        "https://www.tecnomodel-treni.it/images/300x300/75789-1.jpg",
        "https://www.tecnomodel-treni.it/images/300x300/75789-2.jpg",
        "https://www.tecnomodel-treni.it/images/300x300/75789-3.jpg",
        "https://www.tecnomodel-treni.it/images/300x300/75789-4.jpg",
        "https://www.tecnomodel-treni.it/images/300x300/75789-5.jpg"
      ],
      embeddedVideo: [
        {
          id: "sdkjfh983",
          platform: "youtube"
        },
        {
          id: "dfsd98asu",
          platform: "youtube"
        },
        {
          id: "yd345khh9",
          platform: "youtube"
        }
      ],
      embeddedVideoPlatform: [
        {
          value: "youtube",
          text: "YouTube"
        }
      ]
    };
  },
  watch: {
    costPrice(value, oldValue) {
      if (oldValue === undefined) {
        this.calculate();
        this.storeData();
      }
    },
    item() {
      this.getCategoryPath();
    }
  },
  computed: {
    costPrice() {
      return this.item.costPrice;
    },
    listPrice() {
      return this.item.listPrice;
    },
    sellPrice() {
      return this.item.sellPrice;
    },
    vatValue() {
      return this.getVatValue(this.item.vatId);
    },
    costPercentage() {
      return parseFloat(((this.costPrice / this.sellPrice) * 100).toFixed(3));
    },
    vatPercentage() {
      return parseFloat(((this.vatAmount() / this.sellPrice) * 100).toFixed(3));
    },
    markupPercentage() {
      return parseFloat(
        ((this.markupAmount() / this.sellPrice) * 100).toFixed(3)
      );
    },
    percents() {
      return [
        {
          value: this.costPercentage,
          variant: "danger",
          label: "Costo"
        },
        {
          value: this.vatPercentage,
          variant: "warning",
          label: "Tasse"
        },
        {
          value: this.markupPercentage,
          variant: "success",
          label: "Margine"
        }
      ];
    }
  },
  mounted() {
    this.getData();
    this.getBrand();
    //this.getCategory();
  },
  methods: {
    getData: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}item/${this.itemId}`)
        .then(res => {
          this.item = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    markupCalculate() {
      if (this.item.costPrice === 0 || this.item.sellPrice === 0) return null;
      if (!this.item.costPrice || !this.item.sellPrice) return null;
      let sellNetPrice = this.item.sellPrice;
      if (this.vatValue > 0)
        sellNetPrice = this.item.sellPrice / (1 + this.vatValue / 100);
      this.markup = (
        ((sellNetPrice - this.item.costPrice) / this.item.costPrice) *
        100
      ).toFixed(3);
    },
    discountCalculate() {
      if (this.item.sellPrice === 0 || this.item.listPrice === 0) return null;
      if (!this.item.sellPrice || !this.item.listPrice) return null;
      this.discount = (
        ((this.item.listPrice - this.item.sellPrice) / this.item.listPrice) *
        100
      ).toFixed(3);
    },
    sellPriceCalculateByMarkup() {
      if (!this.item.costPrice || !this.markup) return null;
      this.item.sellPrice = (
        this.item.costPrice *
        (1 + this.vatValue / 100) *
        (1 + this.markup / 100)
      ).toFixed(2);
    },
    sellPriceCalculateByDiscount() {
      if (!this.discount || !this.item.listPrice) return null;
      this.item.sellPrice = (
        this.item.listPrice *
        (1 - this.item.discount / 100)
      ).toFixed(2);
    },
    vatAmount() {
      return this.sellPrice - this.sellPrice / (1 + this.vatValue / 100);
    },
    markupAmount() {
      return this.sellPrice - this.costPrice - this.vatAmount();
    },
    calculate() {
      if (this.inputCostPrice) {
        this.sellPriceCalculateByMarkup();
        return null;
      }

      if (this.inputDiscount) this.sellPriceCalculateByDiscount();
      else this.discountCalculate();
      if (this.inputMarkup) this.sellPriceCalculateByMarkup();
      else this.markupCalculate();
    },
    storeData() {
      this.store = {
        costPrice: this.item.costPrice,
        vatId: this.item.vatId,
        markup: this.markup,
        sellPrice: this.item.sellPrice,
        discount: this.discount,
        listPrice: this.item.listPrice
      };
    },
    reset() {
      this.item.costPrice = this.store.costPrice;
      this.item.vatId = this.store.vatId;
      this.markup = this.store.markup;
      this.item.sellPrice = this.store.sellPrice;
      this.discount = this.store.discount;
      this.item.listPrice = this.store.listPrice;
    },
    currencyFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR"
      });
      return currencyFormatter.format(number);
    },
    getVatValue(vatId) {
      return VatValueList[vatId];
    },
    getBrand: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}brand-all`)
        .then(res => {
          this.brands = this.brandSelectAdapter(res.data);
        })
        .catch(err => {
          this.error = err;
        });
    },
    brandSelectAdapter: function(data) {
      let result = [{ value: null, text: "Selezionare un brand" }];
      data.map(function(element) {
        result.push({
          value: element.id,
          text: element.name + " (" + element.itemsNumber + ")",
          brandName: element.name
        });
      });
      return result;
    },
    getCategoryPath: function() {
      let endpoint = `${process.env.VUE_APP_API_ENDPOINT}breadcrumb/${this.item.categoryId}`;
      this.$http.get(endpoint).then(response => {
        let result = [];
        response.data.forEach(function(element) {
          result.push(parseInt(element.id));
        });
        this.categoryPath = result;
      });
    },
    setCategoryId(categoryId) {
      this.item.categoryId = categoryId;
    },
    removeEmbeddedVideo: function(index) {
      this.embeddedVideo.splice(index, 1);
    },
    insertEmbeddedVideo: function() {
      this.addParcel();
    },
    addParcel: function() {
      this.embeddedVideo.push({
        id: null,
        platform: null
      });
    },
    updateImages() {
      this.item.images.thumb = this.immagini;
    }
  }
};
</script>
