<template>
  <div>
    <div class="mb-3">
      <label
        for="images"
        class="drop-container"
        :style="'background-color: #' + backgroundColor + ';'"
        @click="
          $refs.files.click();
          uploadPercentage = 0;
        "
      >
        <span>Clicca qui per scegliere uno o più file o trascinali qui</span>
        <input
          v-show="false"
          type="file"
          ref="files"
          multiple
          @change="handleFilesUploadAndSubmit"
        />
      </label>
    </div>
    <div>
      <b-progress
        :value="uploadPercentage"
        max="100"
        show-progress
        :animated="uploadPercentage < 100"
        :variant="uploadPercentage < 100 ? 'primary' : 'success'"
      >
        <b-progress-bar :value="uploadPercentage">
          <strong>{{ uploadPercentage }} %</strong>
        </b-progress-bar>
      </b-progress>
    </div>
  </div>
</template>

<script>
export default {
  name: "Upload",
  data() {
    return {
      files: [],
      uploadPercentage: 0
    };
  },
  props: {
    name: {
      type: String,
      default: "files"
    },
    backgroundColor: {
      type: String,
      default: "ffffff"
    },
    keepFilename: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    submitFiles() {
      let that = this;
      let formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append(this.name + "[" + i + "]", this.files[i]);
      }
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
        .then(function() {
          console.log("SUCCESS!!");
          that.emitSuccess();
        })
        .catch(function() {
          console.log("FAILURE!!");
        });
    },
    handleFilesUploadAndSubmit() {
      let uploadedFiles = this.$refs.files.files;
      for (let i = 0; i < uploadedFiles.length; i++) {
        this.files.push(uploadedFiles[i]);
      }
      this.submitFiles();
    },
    emitSuccess() {
      this.$emit("success", true);
    }
  }
};
</script>

<style scoped>
.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 20px;
  border-radius: 10px;
  border: 1px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}
</style>
