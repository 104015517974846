<template>
  <b-progress max="100" class="mb-3" show-progress>
    <b-progress-bar
      v-for="(percent, key) in percents"
      :key="key"
      :variant="percent.variant"
      :value="percent.value > 0 ? percent.value : 0"
      :label="getLabel(percent)"
      :title="getLabel(percent)"
    />
  </b-progress>
</template>

<script>
export default {
  name: "PricingBar",
  props: {
    percents: {
      type: Array
    },
    maxAmount: null
  },
  methods: {
    getLabel(percent) {
      let result = "";
      if (percent.label) result = percent.label + " ";
      result += percent.value + "%";
      if (this.maxAmount)
        result +=
          " [" + this.currencyFormatter(this.getAmount(percent.value)) + "]";
      return result;
    },
    getAmount(percent) {
      if (!percent) return null;
      if (percent === 100) return this.maxAmount;
      return percent * (this.maxAmount / 100);
    },
    currencyFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR"
      });
      return currencyFormatter.format(number);
    }
  }
};
</script>
