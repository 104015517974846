<template>
  <div>
    <span v-for="(categoryId, index) in categoryListId" :key="index">
      <b-select
        v-if="category[index] && category[index].length > 0"
        v-model="categoryIds[index]"
        :options="category[index]"
        :select-size="5"
        class="mr-2"
        style="width: 20%"
        @change="setValue(index)"
      />
    </span>
    <span v-if="selected" :title="categoryId">
      <font-awesome-icon
        size="4x"
        :icon="['fas', 'check-circle']"
        class="text-success"
    /></span>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: "ProductCategory",
  data() {
    return {
      category: [],
      categoryIds: []
    };
  },
  props: {
    categoryPath: Array
  },
  computed: {
    categoryListId() {
      let result = [0];
      return result.concat(this.categoryIds);
    },
    categoryId() {
      let categoryIds = this.categoryIds.toString().split(",");
      let result = categoryIds.pop();
      if (result === "") result = categoryIds.pop();
      return parseInt(result);
    },
    selected() {
      return (
        this.category &&
        this.category[this.category.length - 1] &&
        this.category[this.category.length - 1].length === 0
      );
    }
  },
  watch: {
    categoryIds: {
      handler: function() {
        this.setAllCategoryIds();
      },
      deep: true
    },
    category: {
      handler: function() {
        if (this.category) {
          let lastKey;
          for (let i = 0; i < this.category.length; i++) {
            if (this.category[i] && this.category[i].length === 0) {
              lastKey = i;
              break;
            }
          }
          if (lastKey && this.category.length > lastKey + 1) {
            this.category = this.category.slice(0, lastKey + 1);
          }
        }
      },
      deep: true
    },
    categoryPath() {
      this.categoryIds = this.categoryPath;
    },
    categoryId() {
      if (this.selected) this.emitCategoryId(this.categoryId);
    }
  },
  methods: {
    setCategory: function(index) {
      let endpoint = `${process.env.VUE_APP_API_ENDPOINT}category-flat/${this.categoryListId[index]}`;
      this.$http.get(endpoint).then(response => {
        Vue.set(
          this.category,
          index,
          this.categorySelectAdapter(response.data)
        );
      });
    },
    categorySelectAdapter: function(data) {
      let result = [];
      data.map(function(element) {
        result.push({
          value: parseInt(element.id),
          text: element.name
        });
      });
      return result;
    },
    setAllCategoryIds() {
      let index = 0;
      do {
        this.setCategory(index);
        index++;
      } while (index < this.categoryListId.length);
    },
    setValue(index) {
      if (this.categoryIds.length > index + 1)
        this.categoryIds = this.categoryIds.slice(0, index + 1);
    },
    emitCategoryId(categoryId) {
      this.$emit("categoryId", categoryId);
    }
  }
};
</script>
